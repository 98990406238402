<template>
  <div v-if="isLoading">
    <OtherLoading />
  </div>
  <div v-else>
    <div class="title-tabs">General Infomation</div>
    <div class="bg-white p-3">
      <b-row>
        <b-col xs sm="12" md lg xl="6">
          <InputText
            textFloat="Name"
            placeholder="Name"
            type="text"
            name="name"
            isRequired
            v-model="form.name"
            class="mb-2"
            :isValidate="v.form.name.$error"
            :v="v.form.name"
          />
        </b-col>
      </b-row>
    </div>
    <div class="title-tabs">
      <b-row>
        <b-col cols="6">Conditions</b-col>
        <b-col cols="6" class="text-right">
          <button
            @click.prevent="showCollapse"
            class="btn-edit w-100 text-right"
          >
            Edit
          </button>
        </b-col>
      </b-row>
    </div>
    <div class="bg-white black-color">
      <div class="p-3" v-if="isSelectedCondition">
        <DisplayResultAutomation
          :memberLevel="memberLevel"
          :stampList="stampCard"
          :gender="genderList"
          :branchList="branchList"
          :productList="productList"
          :campaignList="campaignList"
          :serviceList="serviceList"
          :privilegeList="privilegeCodeList"
          :settingValue="isSelectedCondition"
          :conditionList="conditionList"
          :form="form"
          :display="3"
        />
      </div>
    </div>
    <SidebarConditionPanel
      ref="sidebarCondition"
      :selected="isSelectedCondition"
      :redeemItemList="redeemItemList"
      :conditionList="conditionList"
      :gender="genderList"
      :memberLevel="memberLevel"
      :stampList="stampCard"
      :branchList="branchList"
      :productList="productList"
      :campaignList="campaignList"
      :serviceList="serviceList"
      :privilegeList="privilegeCodeList"
      :formMain="form"
      :operatorList="operatorList"
      :listCondition="JSON.parse(JSON.stringify(form.condition))"
      @settingCondition="settingCondition"
      @setForm="setForm"
      :v="v.form.condition"
    />
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import SidebarConditionPanel from "@/components/segment/detail/setUp/SidebarConditionPanel";
import DisplayResultAutomation from "@/components/segment/component/DisplayResultAutomation";

export default {
  components: {
    OtherLoading,
    SidebarConditionPanel,
    DisplayResultAutomation,
  },
  props: {
    id: {
      required: true,
      type: [String, Number],
    },
    form: {
      required: true,
      type: Object,
    },
    v: {
      required: true,
      type: Object,
    },
    isSelectedCondition: {
      required: false,
      type: [String, Number],
    },
  },
  data() {
    return {
      isLoading: true,
      showCondition: false,
      operatorList: [
        {
          id: 1,
          name: ">",
        },
        {
          id: 2,
          name: "<",
        },
        {
          id: 3,
          name: "=",
        },
        {
          id: 4,
          name: ">=",
        },
        {
          id: 5,
          name: "<=",
        },
        {
          id: 6,
          name: "between",
        },
      ],
      stampCard: [],
      redeemItemList: [],
      memberLevel: [],
      genderList: [],
      campaignFieldConditions: [],
      privilegeCodeList: [],
      conditionList: [],
      branchList: [],
      productList: [],
      serviceList: [],
      campaignList: [],
    };
  },
  async created() {
    await this.getMemberLevel();
    await this.getGender();
    await this.getCampagin();
    await this.getRedeemItem();
    await this.getStampCard();
    await this.getPrivilegeCode();
    await this.getConditionList();
    await this.getBranchList();
    await this.getProductList();
    await this.getServiceList();
    this.isLoading = false;
  },
  methods: {
    showCollapse() {
      if (this.showCondition === false) {
        this.showCondition = true;
        this.$refs.sidebarCondition.show(true);
      } else {
        this.showCondition = false;
      }
    },
    async getConditionList() {
      await this.$store.dispatch("getCondition");
      let data = this.$store.state.automation.conditionList;

      if (data.result == 1) {
        this.conditionList = data.detail.filter(
          (el) =>
            el.id != 10 &&
            el.id != 12 &&
            el.id != 13 &&
            el.id != 14 &&
            el.id != 20 &&
            el.id != 30 &&
            el.id != 31
        );
      }
    },
    async getGender() {
      await this.$store.dispatch("getGender");
      let data = this.$store.state.automation.genderList;
      if (data.result == 1) {
        for (const gd of data.detail) {
          if (gd.id !== "0") {
            this.genderList.push({
              id: gd.id,
              name: gd.name,
              year: gd.year,
            });
          }
        }
      }
    },
    async getStampCard() {
      await this.$store.dispatch("getStampCardInAutomation");
      const data = this.$store.state.automation.stampCardInAutomation;

      if (data.result == 1) {
        this.stampCard = data.detail;
      }
    },
    async getRedeemItem() {
      const res = await this.$Axios(
        `${process.env.VUE_APP_API}/MarketingAutomation/getredeemitem`
      );
      if (res.data.result == 1) {
        this.redeemItemList = res.data.detail;
      }
    },
    async getMemberLevel() {
      await this.$store.dispatch("getMemberLevel");
      let data = this.$store.state.automation.memberLevel;
      if (data.result == 1) {
        this.memberLevel = data.detail;
      }
    },
    settingCondition(val, form) {
      if (val) {
        if (form) {
          this.form.condition = form;
        } else {
          this.form.condition = [];
        }
        this.$emit("settingCondition", val);
      }
    },
    async getCampagin() {
      await this.$store.dispatch("getCampaign");
      let data = this.$store.state.automation.campaignList;
      if (data.result == 1) {
        this.campaignList = data.detail;
      }
    },
    async getPrivilegeCode() {
      await this.$store.dispatch("getPrivilegeCodeInAutomation");
      const data = this.$store.state.automation.privilegeCodeInAutomation;
      if (data.result == 1) {
        this.privilegeCodeList = data.detail;
      }
    },
    async getBranchList() {
      let data = await this.$Axios(
        `${process.env.VUE_APP_API}/MarketingAutomation/GetBranchList`
      );

      this.branchList = data.data.detail;
    },
    async getProductList() {
      let data = await this.$Axios(
        `${process.env.VUE_APP_API}/MarketingAutomation/GetProductList`
      );
      data.data.detail.length = 3000;
      this.productList = data.data.detail;
    },
    async getServiceList() {
      let data = await this.$Axios(
        `${process.env.VUE_APP_API}/MarketingAutomation/GetServiceList`
      );
      this.serviceList = data.data.detail;
    },
    async sideBarCondition(type) {
      this.$refs.sidebarCondition.show(type);
    },
    setForm(val) {
      this.form.condition = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.black-color {
  color: #000;
}
.btn-edit {
  color: var(--primary-color);
  border: none;
  background-color: transparent;
  font-weight: 600;
  padding: 0 !important;
}
</style>
