<template>
  <div class="position-relative">
    <div class="legend" :id="'legend_' + id" :style="'--id:' + id"></div>
    <div class="position-relative">
      <canvas :id="id" height="150"></canvas>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";
export default {
  props: {
    id: {
      required: true,
      type: String,
    },
    chartData: {
      required: true,
      type: Array,
    },
    label: {
      required: true,
      type: Array,
    },
    text: {
      required: false,
    },
    textRight: {
      required: false,
    },
    type: {
      required: false,
    },
    hideLegend: {
      required: false,
      default: false,
      type: Boolean,
    },
    filterValue: {
      require: false,
      type: String,
    },
  },

  mounted() {
    this.initChart();
  },
  methods: {
    addCommas(nStr) {
      var x, x1, x2;

      nStr += "";
      x = nStr.split(".");
      x1 = x[0];
      x2 = x.length > 1 ? "." + x[1] : "";
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, "$1" + "," + "$2");
      }
      return x1 + x2;
    },
    initChart() {
      var ctx = document.getElementById(this.id);
      var that = this;
      var myChart = new Chart(ctx, {
        type: this.type,
        data: {
          labels: this.label,
          datasets: this.chartData,
        },
        options: {
          tooltips: {
            mode: "index",
            intersect: false,
            callbacks: {
              title: function (tooltipItem, data) {
                return `${data.datasets[tooltipItem[0].datasetIndex].label} (${
                  data.labels[tooltipItem[0].index]
                })`;
              },
              label: function (tooltipItem, data) {
                var datasetLabel =
                  data.datasets[tooltipItem.datasetIndex].label || "Other";
                return datasetLabel + ": " + that.addCommas(tooltipItem.value);
              },
            },
          },
          hover: {
            mode: "index",
            intersect: false,
          },
          responsive: true,
          interaction: {
            mode: "index",
          },
          legend: {
            display: !this.hideLegend,
            align: "center",
            position: "right",
            labels: {
              fontSize: 10,
              boxWidth: 12,
              boxHeight: 12,
              fontFamily: "Kanit-Regular",
              fontColor: "black",
            },
          },

          scales: {
            yAxes: [
              {
                id: "A",
                type: "linear",
                position: "left",
                scaleLabel: {
                  display: this.text == "" ? false : true,
                  fontStyle: "bold",
                  labelString: this.text || "",
                  fontFamily: "Kanit-Regular",
                },
                ticks: {
                  callback: function (label, index, labels) {
                    return that.addCommas(label);
                  },
                  min: 0,
                },
              },
              {
                display: this.textRight,
                id: "B",
                type: "linear",
                position: "right",
                scaleLabel: {
                  display: this.textRight,
                  fontStyle: "bold",
                  labelString: this.textRight || "",
                  fontFamily: "Kanit-Regular",
                },
                ticks: {
                  callback: function (label, index, labels) {
                    return that.addCommas(label);
                  },
                  autoSkip: true,
                  min: 0,
                  beginAtZero: true,
                },
                gridLines: {
                  display: false,
                },
              },
              {
                display: false,
                id: "C",

                ticks: {
                  callback: function (label, index, labels) {
                    return that.addCommas(label);
                  },
                  autoSkip: true,
                  min: 0,

                  beginAtZero: true,
                },
              },
            ],

            xAxes: [
              // {
              //   type: "time",
              //   time: {
              //     unit: this.filterValue.toLowerCase() || "day",
              //     displayFormats: {
              //       day: "DD-MM-YYYY",
              //     },
              //   },
              // },
              {
                //   ticks: {
                //     autoSkip: false,
                //     callback: function (val, index) {
                //       return index == 0 ? "" : index % ticksDate === 0 ? val : "";
                //     },
                //     min: 0,
                //   },
              },
            ],
          },
        },
      });

      // if (!this.hideLegend) {
      //   document.getElementById("legend_" + this.id).innerHTML =
      //     myChart.generateLegend();
      // }
    },
  },
};
</script>

<style lang="scss">
.legend {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}
// .chart-container {
//   margin-right: 100px;
// }
ul {
  padding-inline-start: 0px !important;
}
@media screen and (max-width: 600px) {
  .chart-container {
    margin-bottom: 20px;
    margin-right: unset;
  }
  .legend {
    bottom: 0;
    right: 50%;
    top: 100%;

    transform: translate(50%, -20px);
    ul {
      flex-direction: row !important;
      gap: 5px;
      li {
        margin-bottom: 0 !important;
      }
      span {
        font-size: 9px !important;
        width: max-content;
        max-width: 80px !important;
      }
    }
  }
}
</style>
