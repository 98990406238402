<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <b-form>
        <div>
          <h1 class="mr-sm-4 header-tablepage">SEGMENT</h1>
        </div>
        <b-tabs class="mt-3">
          <b-tab title="Setup">
            <div class="bg-white black-color">
              <SetupPanel
                ref="setupPanel"
                :form="form"
                :v="$v"
                :id="id"
                :isSelectedCondition="conditionSettingValue"
                @settingCondition="settingCondition"
              />
            </div>
            <FooterAction @submit="checkForm()" routePath="/segment" />
          </b-tab>
          <b-tab title="Summary" v-if="parseInt(id) > 0">
            <div class="bg-white">
              <Summary ref="summary" @setStat="setStat" :id="parseInt(id)" />
            </div>
          </b-tab>
          <b-tab title="Customer List" v-if="parseInt(id) > 0">
            <TabCustomerList
              placeholder="Search Name"
              :filter="filterCustomer"
              :fields="fieldsCustomer"
              :items="itemsCustomer"
              :isBusy="isBusyCustomer"
              :rows="rowsCustomerList"
              :pageOptions="pageOptions"
              :showing="showing"
              :showingTo="showingTo"
              @getPage="getPage"
              @getPageOption="getPageOption"
            />
          </b-tab>
        </b-tabs>
      </b-form>
    </div>
  </div>
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import OtherLoading from "@/components/loading/OtherLoading";
import SetupPanel from "@/components/segment/detail/SetupPanel";
import TabCustomerList from "@/components/segment/detail/customer/TabCustomerList";
import Summary from "@/components/segment/summary";

export default {
  name: "segmentDetails",
  components: {
    OtherLoading,
    SetupPanel,
    TabCustomerList,
    Summary,
  },
  data() {
    return {
      id: this.$route.params.id,
      isLoading: true,
      isBusy: true,
      form: {
        name: "",
        condition: [],
      },
      list: {
        privilegeCode: [{ id: 0, name: "Please Select Privilege Code" }],
        condition: [{ id: 0, name: "Please Select Condition" }],
      },
      filterCustomer: {
        id: 0,
        take: 10,
        page: 1,
      },
      fieldsCustomer: [
        {
          label: "Member ID",
          key: "member_id",
        },
        {
          label: "First Name",
          key: "firstNameTh",
        },
        {
          label: "Last Name",
          key: "lastNameTh",
        },
        {
          label: "Telephone",
          key: "telephone",
        },
        {
          label: "Eamil",
          key: "email",
        },
      ],
      itemsCustomer: [],
      isBusyCustomer: true,
      rowsCustomerList: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      showing: 1,
      showingTo: 10,
      result: 0,
      conditionSettingValue: 0,
      freezeCondition: [],
    };
  },
  validations: {
    form: {
      name: { required },
      condition: {
        $each: {
          condition_type_id: {
            required: requiredIf(function (item) {
              return !item.condition_type_id;
            }),
          },
          operation_type_id: {
            required: requiredIf(function (item) {
              return !item.operation_type_id;
            }),
          },
          condition_value: {
            required: requiredIf(function (item) {
              return !item.condition_value;
            }),
          },
        },
      },
    },
  },
  watch: {},
  created: async function () {
    if (this.id !== "0") {
      await this.getData();
      await this.getCustomerList();
    }
    this.isLoading = false;
    this.$v.$reset();
  },
  methods: {
    getData: async function () {
      this.isLoading = true;

      await this.$store.dispatch("getSegmentDetail", this.id);
      var data = await this.$store.state.segment.stateSegmentDetail;

      var tempObj = [];

      this.freezeCondition = [...data.detail.condition];
      if (data.result == 1) {
        for (const list of data.detail.condition) {
          if (
            !tempObj.find(
              (el) => el.condition_type_id == list.condition_type_id
            )
          ) {
            let value = data.detail.condition
              .filter((el) => el.condition_type_id == list.condition_type_id)
              .map((el) => el.condition_value);

            tempObj.push({
              ...list,
              condition_value: value.length == 1 ? value[0] : value,
            });
          }
        }

        data.detail.condition = tempObj;
        this.form = data.detail;
        if (this.form.condition.length) this.conditionSettingValue = 1;
        else this.conditionSettingValue = 2;
      }
      this.isLoading = false;
    },
    settingCondition(val, form) {
      if (val) {
        this.conditionSelect = true;
        if (form) {
          this.form.condition = form;
        }
        this.conditionSettingValue = val;
      }
    },
    async getCustomerList() {
      this.isBusyCustomer = true;
      this.filterCustomer.id = parseInt(this.$route.params.id);
      await this.$store.dispatch("getCustomerSegmentList", this.filterCustomer);
      const data = this.$store.state.segment.customerList;
      this.rowsCustomerList = data.detail.count;
      if (this.rowsCustomerList > 0) {
        this.itemsCustomer = data.detail.result;
      } else {
        this.itemsCustomer = [];
      }
      this.isBusyCustomer = false;
    },
    async checkForm() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.$EventBus.$emit("showLoading");

      await this.submit();

      this.$EventBus.$emit("hideLoading");
    },
    async submit() {
      let payload = { ...this.form };
      let data = null;

      var tempObj = [];

      for (const list of payload.condition) {
        let condition = { ...list };
        if (typeof list.condition_value == "object") {
          // console.log(list);
          for (const value of list.condition_value) {
            let find = this.freezeCondition.find(
              (el) => el.condition_value == value
            );
            if (!find) {
              delete condition.id;
            }
            tempObj.push({ ...condition, condition_value: value });
          }
        } else {
          tempObj.push(condition);
        }
      }

      payload.condition = tempObj;

      if (this.id == 0) {
        await this.$store.dispatch("createSegmentList", payload);
        data = this.$store.state.segment.stateCreateSegment;
      } else {
        await this.$store.dispatch("updateSegmentList", payload);
        data = this.$store.state.segment.stateUpdateSegment;
      }
      if (data.result == 1) {
        this.successAlert().then(() => this.$router.push("/segment"));
      } else {
        this.errorAlert(data.message);
      }
    },
    getPage(value) {
      this.filterCustomer = value;
      this.getCustomerList();
    },
    getPageOption(value) {
      this.filterCustomer = value;
      this.getCustomerList();
    },
    searchFilter(value) {
      this.filterCustomer = value;
      this.getCustomerList();
    },
    sidebarFilter() {
      this.$refs.summary.$refs.filter.show();
    },
    setStat(val, date) {
      // this.displayDateFilter = date;
      // if (val) {
      //   this.stat.totalCustomer = val.totalCustomer;
      //   this.stat.percentCustomer = val.percentCustomer;
      // }
    },
  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 350px) and (max-width: 991px) {
  .footer-action {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
.footer-action {
  margin-left: var(--margin-spacing);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  background: #fff;
  display: flex;
  flex-direction: row;
  transition: margin-left 0.25s, margin-right 0.25s, width 0.25s, flex 0.25s;
  z-index: 600;
}
::v-deep .nav-tabs .nav-link:not(.active):hover {
  background-color: transparent;
  color: var(--primary-color);
  border-bottom: 4px solid var(--primary-color);
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}
::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: transparent !important;
  color: var(--primary-color) !important;
  border-bottom: 4px solid var(--primary-color) !important;
}
</style>
