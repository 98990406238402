<template>
  <div>
    <TableCustomerList
      :fields="fields"
      :items="items"
      :isBusy="isBusy"
      :filter="filter"
      :rows="rows"
      :pageOptions="pageOptions"
      :showing="showing"
      :showingTo="showingTo"
      @getPage="getPage"
      @getPageOption="getPageOption"
    />
  </div>
</template>

<script>
import TableCustomerList from "@/components/segment/detail/customer/TableCustomerList";
export default {
  components: {
    TableCustomerList,
  },
  props: {
    filter: {
      required: true,
      type: Object,
    },
    fields: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    rows: {
      required: true,
      type: Number,
    },
    pageOptions: {
      required: true,
      type: Array,
    },
    showing:{
      required: true,
      type: Number
    },
    showingTo:{
      required: true,
      type: Number
    },
    placeholder:{
      required:false,
      type:String
    }
  },
  methods:{
    getPage(value){
      this.$emit("getPage",value);
    },
    getPageOption(value){
      this.$emit("getPageOption",value);
    },
  }
};
</script>

<style lanng="scss" scoped>
</style>
